//import { UrlBase } from './base-service'
import { UrlMenu } from "../js/scripts/functions";
import { UrlPage } from "../js/scripts/constants";
import { Session } from '../js/scripts/session';

//##############################################################################################################
// ARQUIVO DE SERVIÇOS DA APLICAÇÃO QUE SE COMUNICAM COM O BACKEND
//##############################################################################################################

export class Service {

  constructor() {
    this.addressList = []
    this.resourceList = []
    this.resultReservation = false
    this.ctoAvailability = {}
    this.ontServiceTeste = {}
    this.activation = false
    this.suspension = false
    this.resume = false
    this.returnList = []
    this.returnObject = {}
  }

  //##############################################################################################################
  // FUNCTION: EfetuarLogin
  // Details: Realiza o pré-login, antes do 2FA. Envia a request para o backend e aguarda retorno.
  // Parâmetros de envio: email, senha
  // Parâmetros de resposta: null ou usuario válido
  //##############################################################################################################
  static EfetuarLogin = async (email, senha) => {
    var request = {
      Email: email,
      Senha: senha
    };

    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/usuario/login"), {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(request),
    })
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((data) => {
        this.usuario = data
      })
      
    return this.usuario;
  }

  //##############################################################################################################
  // FUNCTION: EsqueciMinhaSenha
  // Details: Solicita alteração de senha
  // Parâmetros de envio: email
  // Parâmetros de retorno: 
  //##############################################################################################################
  static EsqueciMinhaSenha = async (email) => {
    var request = {
      Email: email
    };

    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/usuario/esqueciSenha"), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(request),
    })
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((data) => {
        this.usuario = data
      })

    return this.usuario;
  }


  //##############################################################################################################
  // FUNCTION: LOGIN
  // Details: 
  //##############################################################################################################
  static LoginUserWithToken2f = async (id, email, token2f) => {
    var request = {
      Id: id,
      Email: email,
      Token2f: token2f
    };

    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/usuario/login2f"), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(request),
    })
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((data) => {
        this.usuario = data
      })

    return this.usuario;
  }

  //##############################################################################################################
  // FUNCTION: LOGIN
  // Details: 
  //##############################################################################################################
  static RegenerateToken = async (email) => {
    var request = {
      Email: email
    }

    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/usuario/newtoken"), {
      mode: 'cors',
      credentials: 'omit',

      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: "POST",
      body: JSON.stringify(request),
    })
      .then((response) => {

        if (response.status == 200) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((data) => {
        this.usuario = data
      })

    return this.usuario;
  }

  //##############################################################################################################
  // FUNCTION: LOGIN
  // Details: 
  //##############################################################################################################
  static Session = async () => {
    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "authlayer/v1/login/verify-session"), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {

        this.usuario = data
      })

    return this.usuario;
  }

  //##############################################################################################################
  // FUNCTION: LOCATION BY ADDRESS
  // Details: 
  //##############################################################################################################
  static GetLocationByAddress = async (cep, numero) => {
    var request = {
      PostCode: cep,
      StreetNrFirst: numero
    };

    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/locationByAddress"), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'UsuarioId': Session.UsuarioId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: "POST",
      body: JSON.stringify(request),
    })
      .then((response) => {

        if (response.status == 200) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((result) => {

        if (result.data != "NOT_FOUND") {
          this.addressList = {
            success: true,
            data: result,
            message: null
          }
        }
        else {
          this.addressList = result
        }
      })

    return this.addressList;
  }

  //##############################################################################################################
  // FUNCTION: LOCATION BY ADDRESS
  // Details: 
  //##############################################################################################################
  static GetLocationByCoordinate = async (pLatitude, pLongitude) => {
    var request = {
      latitude: pLatitude.toString(),
      longitude: pLongitude.toString()
    };

    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/locationByCoordinate"), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'VnoID': Session.VnoId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: "POST",
      body: JSON.stringify(request),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {

        if (result.data.responseStatus == 404 || result.data.responseStatus == 500) {
          this.addressList = result.data.responseStatus
        }
        else {
          this.addressList = result.data
        }
      })

    return this.addressList;
  }

  //##############################################################################################################
  // FUNCTION: DELETE RESOURCE
  // Details: Função qwue apaga o recurso
  //##############################################################################################################
  static DeleteResourceReservation = async (id) => {
    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/resourceReservation/delete/" + id), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'UsuarioId': Session.UsuarioId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: 'DELETE'
    })
      .then((response) => {

        if (response.status == 200 || response.status == 201) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((response) => {
        this.resultReservation = response
      })

    return this.resultReservation
  }

  //##############################################################################################################
  // FUNCTION: RESERVATION
  // Details: Função qwue efetua a reserva
  //##############################################################################################################
  static ReserveResource = async (
    serviceId, 
    templateId, 
    addressId, 
    ctoId, 
    infoAdd,
    segment, 
    serviceTypeId) => {

    var reservationRequest = {
      ServiceId: serviceId,
      TemplateId: templateId,
      AddressId: addressId,
      CtoId: ctoId,
      Info: infoAdd,
      Usuario: Session.Email,
      Availability: JSON.parse(localStorage.getItem('_viability_address')),
      SegmentType: segment,
      ServiceTypeId: serviceTypeId
    }

    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/resourceReservation"), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'UsuarioId': Session.UsuarioId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: "POST",
      body: JSON.stringify(reservationRequest),
    })

      .then((response) => {

        if (response.status == 200 || response.status == 201) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((response) => {
        this.resultReservation = response
      })

    return this.resultReservation

  }

  //##############################################################################################################
  // FUNCTION: AVAILABILTY 
  // Details: Função qwue verifica a viabilidade do endereço passado
  //##############################################################################################################
  static Availability = async (addressId) => {

    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/availability/" + addressId), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'UsuarioId': Session.UsuarioId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: "GET",
    })
      .then((response) => {

        if (response.status == 200 || response.status == 201) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((response) => {

        if (isNaN(response)) {

          this.ctoAvailability = response.data
        }
        else {
          this.ctoAvailability = response
        }

      })



    return this.ctoAvailability;
  }

  //##############################################################################################################
  // FUNCTION: 
  // Details: Função qwue busca os servicesIds cadastrados com base no seu status
  //##############################################################################################################
  static GetResourcesByStatus = async (status) => {

    //GET
    //"https://authlayerapi.azurewebsites.net
    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/resourceReservation/serviceid/status/" + status), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: 'GET',
    })
      .then((response) => {

        if (response.status == 200) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((response) => {
        if (isNaN(response)) {
          this.resourceList = response.data
        }
        else {
          this.resourceList = response
        }
      })

    return this.resourceList;
  }

  //##############################################################################################################
  // FUNCTION: 
  // Details: Função qwue busca os servicesIds cadastrados com base no seu status
  //##############################################################################################################
  static ObterCircuitosReservados = async (dias) => {
    var dias = 120; // 720 dias; 2 anos

    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/resourceReservation/serviceid/reserved/" + dias), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'UsuarioId': Session.UsuarioId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: 'GET'
    })
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((response) => {
        this.resourceList = response.data
      })

    return this.resourceList;
  }

  //##############################################################################################################
  // FUNCTION: 
  // Details: Função qwue busca os servicesIds cadastrados com base no seu status
  //##############################################################################################################
  static ObterCircuitosAtivados = async (dias) => {

    var dias = 120 // 720 dias; 2 anos

    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/resourceReservation/serviceid/activated/" + dias), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'UsuarioId': Session.UsuarioId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: 'GET',
    })
      .then((response) => {

        if (response.status == 200) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((response) => {
        // if (isNaN(response)) {
        //   this.resourceList = response.data
        // }
        // else {
        //   this.resourceList = response
        // }
        this.resourceList = response
      })

    return this.resourceList;
  }

  //##############################################################################################################
  // FUNCTION: 
  // Details: Função qwue busca os servicesIds cadastrados com base no seu status
  //##############################################################################################################
  static GeResourceGeneral = async (serviceId, ativacao, datainicio, datafim, downstream) => {


    var sidRequest = {
      ServiceId: serviceId,
      Ativacao: ativacao,
      Datainicio: datainicio,
      Datafim: datafim,
      Downstream: downstream,
    }

    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/resourceReservation/serviceid/general"), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'UsuarioId': Session.UsuarioId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: 'POST',
      body: JSON.stringify(sidRequest),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.resourceList = data
      })

    return this.resourceList;
  }



  //##############################################################################################################
  // FUNCTION: ACTIVATION 
  // Details: Função que ativa o usuário na rede
  //##############################################################################################################
  static ResourceActivation = async (item, serialNumber) => {
    var request = {
      SerialNumber: serialNumber
    }

    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/resourceReservation/activation/" + item.serviceid), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'VnoName': item.vno,
        'VnoId': item.vnO_ID,
        'UsuarioId': Session.UsuarioId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: "PATCH",
      body: JSON.stringify(request),
    })
      .then((response) => {

        if (response.status == 200 || response.status == 201) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((response) => {
        this.activation = response
      })

    return this.activation;
  }

  //##############################################################################################################
  // FUNCTION: SUSPENSION 
  // Details: Função qwue suspende o usuário na rede
  //##############################################################################################################
  static ResourceSuspension = async (serviceId) => {

    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/resourceReservation/suspend/" + serviceId), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'UsuarioId': Session.UsuarioId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: "PATCH",
    })
      .then((response) => {

        if (response.status == 200 || response.status == 201) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((data) => {
        this.suspension = data
      })

    return this.suspension;
  }

  //##############################################################################################################
  // FUNCTION: Função que reativa o usuário na rede
  // Details: 
  //##############################################################################################################
  static ResourceResume = async (serviceId) => {

    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/resourceReservation/resume/" + serviceId), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'UsuarioId': Session.UsuarioId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: "PATCH",
    })
      .then((response) => {

        if (response.status == 200 || response.status == 201) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((data) => {
        this.resume = data
      })

    return this.resume;
  }

  //##############################################################################################################
  // FUNCTION: Função que faz o GET do Service Test na Gerência
  // Details: 
  //##############################################################################################################
  static GetOntServiceTest = async (id) => {
    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/service/test/" + id), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: 'GET'
    })
      .then((response) => {

        if (response.status == 200 || response.status == 201) {
          return response.json();
        }
        else {
          return response.status
        }

      })
      .then((response) => {


        if (isNaN(response)) {

          this.ontServiceTeste = response.data
        }
        else {
          this.ontServiceTeste = response
        }
      })


    return this.ontServiceTeste;
  }

  //##############################################################################################################
  // FUNCTION: Função que faz o GET do Service Test na Gerência
  // Details: 
  //##############################################################################################################
  static GetCustomers = async (id) => {
    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "authlayer/v1/resourceReservation/phisicalPath/" + id), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: 'GET'
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.customers = data
      })

    return this.customers;
  }


  //##############################################################################################################
  // FUNCTION: GetResourceReservation
  // Details: Busca os dados da reserva na Mulesoft
  //##############################################################################################################
  static GetResourceReservation = async (item) => {
    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/resourceReservation/phisicalPath/" + item.serviceid), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'VnoName': item.vno,
        'VnoId': item.vnO_ID,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: 'GET'
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        this.customers = response
      })
      .catch((err) => {
        console.log(err)
      })

    return this.customers;
  }

  //##############################################################################################################
  // FUNCTION: SUSPENSION 
  // Details: Função qwue suspende o usuário na rede
  //##############################################################################################################
  static SpeedChange = async (serviceId, request) => {

    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/serviceOrdering/SpeedChange/" + serviceId), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'UsuarioId': Session.UsuarioId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: "PATCH",
      body: JSON.stringify(request),
    })
      .then((response) => {

        if (response.status == 200 || response.status == 201) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((data) => {
        this.data = data
      })

    return this.data;
  }

  //##############################################################################################################
  // FUNCTION: SUSPENSION 
  // Details: Função qwue suspende o usuário na rede
  //##############################################################################################################
  static ServiceChange = async (serviceId, request) => {

    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/serviceOrdering/ServiceChange/" + serviceId), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'UsuarioId': Session.UsuarioId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: "PATCH",
      body: JSON.stringify(request),
    })
      .then((response) => {

        if (response.status == 200 || response.status == 201) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((data) => {
        this.data = data
      })

    return this.data;
  }

  //##############################################################################################################
  // FUNCTION: SUSPENSION 
  // Details: Função qwue suspende o usuário na rede
  //##############################################################################################################
  static EquipmentChange = async (serviceId, request) => {

    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/serviceOrdering/EquipmentChange/" + serviceId), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'UsuarioId': Session.UsuarioId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: "PATCH",
      body: JSON.stringify(request),
    })
      .then((response) => {

        if (response.status == 200 || response.status == 201) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((data) => {
        this.data = data
      })

    return this.data;
  }

  //##############################################################################################################
  // FUNCTION: 
  // Details: Função qwue busca os servicesIds cadastrados com base no seu status
  //##############################################################################################################
  static Reset = async (serviceId) => {

    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/troubleshooting/reset/" + serviceId), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'UsuarioId': Session.UsuarioId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: 'GET',
    })
      .then((response) => {

        if (response.status == 200 || response.status == 201) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((data) => {
        this.resourceList = data
      })

    return this.resourceList;
  }

  //##############################################################################################################
  // FUNCTION: 
  // Details: Função qwue busca os servicesIds cadastrados com base no seu status
  //##############################################################################################################
  static Reapply = async (serviceId) => {

    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/troubleshooting/reapply/" + serviceId), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'UsuarioId': Session.UsuarioId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: 'GET',
    })
      .then((response) => {

        if (response.status == 200 || response.status == 201) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((data) => {
        this.resourceList = data
      })

    return this.resourceList;
  }

  //##############################################################################################################
  // FUNCTION: 
  // Details: Função qwue busca os servicesIds cadastrados com base no seu status
  //##############################################################################################################
  static GetSpeedVno = async () => {

    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/vno/speeds"), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: 'GET',
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.returnList = data
      })

    return this.returnList;
  }

  //##############################################################################################################
  // FUNCTION: Função que faz o GET do Service Test na Gerência
  // Details: 
  //##############################################################################################################
  static GatherAndAnalyzeResourceInformation = async (itemSelected) => {


    var pCabinet = itemSelected.cabinet.name
    var pSplitterN1 = itemSelected.cabinet.splitter[0].id
    var pTerminalbox = itemSelected.cabinet.splitter[1].id

    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/maneuver/gatherAndAnalyzeResourceInformation?cabinet=" + pCabinet + "&splitterN1=" + pSplitterN1 + "&terminalbox=" + pTerminalbox), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: 'GET'
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        this.customers = response
      })
      .catch((err) => {
        console.log(err)
      })

    return this.customers;
  }

  //##############################################################################################################
  // FUNCTION: 
  // Details: Função qwue busca os servicesIds cadastrados com base no seu status
  //##############################################################################################################
  static PostManobra = async (serviceId, motivoManobra, ctoPortIdDestino, reserva) => {

    var sidRequest = {
      ServiceId: serviceId,
      CtoName: reserva.cabinet.splitter[1].id,
      PortIdOrigem: reserva.cabinet.hasShelves[0].hasCards[0].containsPorts[0].id.toString(),
      PortIdDestino: ctoPortIdDestino.toString(),
      Motivo: motivoManobra
    }

    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/maneuver/establishResourceStrategyArchitecture"), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: 'POST',
      body: JSON.stringify(sidRequest),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.resourceList = data
      })
    return this.resourceList;
  }

  //##############################################################################################################
  // FUNCTION: GetAccessPage
  // Details: 
  //##############################################################################################################
  static GetAccessPage = async (pageId) => {
    var request = {
      Id: Session.UsuarioId,
      Email: Session.Email
    }

    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/session/access/page/" + pageId), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: "POST",
      body: JSON.stringify(request),
    })
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((data) => {
        this.usuario = data
      })

    return this.usuario;
  }

  static ValidAccessPage = async (pageId) => {
    if (!Session.isSessionValid()) {
      window.location.href = '/' + __VITE_BASE__
    }

    var retorno = false
    
    await this.GetAccessPage(pageId)
      .then((response) => {
        if (!response.success) {
          if (response.data != null) {
            location.href = UrlMenu(UrlPage.ACESSO_NEGADO)
          }
          else {
            Session.removeSession();
            window.location.href = '/' + __VITE_BASE__
          }
        }
        else {
          retorno = true
        }
      })
    return retorno
  }

  static ValidAccessFunction = async (pageId) => {
    if (!Session.isSessionValid()) {
      window.location.href = '/' + __VITE_BASE__
    }

    var retorno = false

    await this.GetAccessPage(pageId)
      .then((response) => {
        if (!response.success) {
          if (response.data != null) {
            retorno = false
          }
          else {
            retorno = false
          }
        }
        else {
          retorno = true
        }
      });
    
    return retorno;
  }

  static GetVnos = async () => {
    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/vnoManagement/isps"), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'UsuarioId': Session.UsuarioId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: "GET"
    })
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((data) => {
        this.usuario = data
      })

    return this.usuario;
  }

  static GetPerfis = async () => {

    //GET
    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/perfis"), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'UsuarioId': Session.UsuarioId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: "GET",
    })
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((data) => {
        this.usuario = data
      })

    return this.usuario;
  }

  static GetUsuarios = async () => {

    //GET
    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/usuario"), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'UsuarioId': Session.UsuarioId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: "GET",
    })
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((data) => {

        this.usuario = data
      })

    return this.usuario;
  }

  static GetUsuariosByIsp = async (isp) => {

    //GET
    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/usuario/isp/" + isp), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: "GET",
    })
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((data) => {

        this.usuario = data
      })

    return this.usuario;
  }

  static GetFuncionalidades = async () => {

    //GET
    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/funcionalidade"), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'UsuarioId': Session.UsuarioId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: "GET",
    })
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((data) => {
        this.usuario = data
      })

    return this.usuario;
  }

  static GetFuncionalidadesPorPerfil = async (perfilId) => {

    //GET
    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/funcionalidade/perfil/" + perfilId), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'UsuarioId': Session.UsuarioId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: "GET",
    })
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((data) => {
        this.usuario = data
      })

    return this.usuario;
  }

  static GetFuncionalidadesPorUsuario = async (usuarioId) => {

    //GET
    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/funcionalidade/usuario/" + usuarioId), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: "GET",
    })
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((data) => {
        this.usuario = data
      })

    return this.usuario;
  }

  //##############################################################################################################
  // FUNCTION: CriarUsuario
  // Details: Criacao de novo usuario
  //##############################################################################################################
  static CriarUsuario = async (usuario) => {


    //GET
    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/usuario/criar"), {
      mode: 'cors',
      credentials: 'omit',

      //headers: myHeadersExp,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'Authorization': 'Bearer ' + Session.JwtToken

      },
      method: "POST",
      //body: JSON.stringify(reservationRequest._body),
      body: JSON.stringify(usuario),
    })
      .then((response) => {

        if (response.status == 200) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((data) => {
        this.usuario = data
      })

    return this.usuario;

  }

  static AlterarUsuario = async (usuario) => {

    //GET
    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/usuario/" + usuario.id + "/alterar"), {
      mode: 'cors',
      credentials: 'omit',

      //headers: myHeadersExp,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'Authorization': 'Bearer ' + Session.JwtToken

      },
      method: "PATCH",
      //body: JSON.stringify(reservationRequest._body),
      body: JSON.stringify(usuario),
    })
      .then((response) => {

        if (response.status == 200) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((data) => {
        this.usuario = data
      })

    return this.usuario;

  }

  static ChangePassword = async (email, senha) => {
    var usuario = {
      email: email,
      senha: senha
    }

    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/usuario/troca-senha"), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: "POST",
      body: JSON.stringify(usuario),
    })
      .then((response) => {

        if (response.status == 200) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((data) => {
        this.usuario = data
      })

    return this.usuario;

  }

  //##############################################################################################################
  // FUNCTION: ACTIVATION 
  // Details: Função que ativa o usuário na rede
  //##############################################################################################################
  static DesativarUsuario = async (usuarioId) => {

    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/usuario/desativar/" + usuarioId), {
      //await fetch(encodeURI(new URL(baseUrl) + "activation/serviceid"), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'UsuarioId': Session.UsuarioId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: "PATCH",
    })
      .then((response) => {

        if (response.status == 200 || response.status == 201) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((response) => {
        this.activation = response
      })

    return this.activation;
  }

  //##############################################################################################################
  // FUNCTION: 
  // Details: Função qwue busca os servicesIds cadastrados com base no seu status
  //##############################################################################################################
  static AlarmesOnt = async (serviceId) => {

    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/troubleshooting/alarms/" + serviceId), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'UsuarioId': Session.UsuarioId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: 'GET',
    })
      .then((response) => {

        if (response.status == 200 || response.status == 201) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((response) => {
        if (isNaN(response)) {
          this.returnList = response.data
        }
        else {
          this.returnList = response
        }
      })

    return this.returnList;
  }

  //##############################################################################################################
  // FUNCTION: 
  // Details: Função qwue busca os servicesIds cadastrados com base no seu status
  //##############################################################################################################
  static EstatisticasTrafego = async (serviceId) => {

    await fetch(encodeURI(new URL(__ISPPORTAL_API__) + "api/v1/troubleshooting/statistics/" + serviceId), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'VnoName': Session.VnoName,
        'VnoId': Session.VnoId,
        'UsuarioId': Session.UsuarioId,
        'Authorization': 'Bearer ' + Session.JwtToken
      },
      method: 'GET',
    })
      .then((response) => {

        if (response.status == 200 || response.status == 201) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((response) => {

        if (isNaN(response)) {
          this.returnList = response.data
        }
        else {
          this.returnList = response
        }
      })

    return this.returnList;
  }


}
